import { Components } from "@mui/material";
import dynamic from "next/dynamic";

import { baseTheme } from "src/theme/base";
import { EIcon, Icon } from "src/components/Icon";

const DataGridNoRowsOverlay = dynamic(
  () => import("src/components/DataGrid/DataGridNoRowsOverlay")
);

export const MuiDataGrid: NonNullable<Components["MuiDataGrid"]> = {
  defaultProps: {
    autoHeight: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    disableRowSelectionOnClick: true,
    components: {
      NoRowsOverlay: DataGridNoRowsOverlay,
      MoreActionsIcon: () => <Icon icon={EIcon.MORE_VERTICAL} alt="" />,
    },
    pagination: true,
    paginationMode: "server",
    pageSizeOptions: [10, 25, 50, 100],
  },
  styleOverrides: {
    main: {
      borderRadius: "5px",
      backgroundColor: baseTheme.palette.common.white,
      borderColor: baseTheme.palette.divider,
      borderStyle: "solid",
      borderWidth: "1px",
    },

    columnHeaders: {
      borderBottomColor: baseTheme.palette.divider,
    },

    columnHeader: {
      "&:focus-visible": {
        outlineOffset: "-2px", // account for the border width
      },
    },

    columnHeaderTitle: {
      color: baseTheme.palette.text.secondary,
      fontWeight: "bold",
      fontSize: "0.75rem",
      textTransform: "uppercase",
      lineHeight: "1rem",
      textWrap: "wrap",
    },

    root: {
      // only show the focus ring when the user is using the keyboard
      "& *:focus:not(:focus-visible), & *:focus-within:not(:focus-visible)": {
        outline: "none !important",
      },
    },
  },
};
