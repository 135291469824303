import { createTheme } from "@mui/material";

import { breakpoints } from "./breakpoints";
import { palette } from "./palette";
import { shape } from "./shape";
import { spacing } from "./spacing";
import { typography } from "./typography";

export const baseTheme = createTheme({
  spacing,
  breakpoints,
  palette,
  shape,
  typography,
});
