import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiCardHeader: NonNullable<Components["MuiCardHeader"]> = {
  styleOverrides: {
    root: {
      padding: baseTheme.spacing(2),
      paddingBottom: 0,
    },
  },
};
