import { ComponentProps } from "react";
import {
  NextIntlProvider,
  NumberFormatOptions,
  DateTimeFormatOptions,
} from "next-intl";

export const formats: {
  number: Record<
    "amount" | "preciseAmount" | "percent" | "precisePercent",
    NumberFormatOptions
  >;
  dateTime: Record<
    "datetime" | "date" | "time" | "yearMonth",
    DateTimeFormatOptions
  >;
} = {
  number: {
    amount: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    preciseAmount: { minimumFractionDigits: 2, maximumFractionDigits: 6 },
    percent: { maximumFractionDigits: 2, style: "percent" },
    precisePercent: { maximumFractionDigits: 6, style: "percent" },
  },
  dateTime: {
    datetime: { dateStyle: "medium", timeStyle: "medium" },
    date: { dateStyle: "medium" },
    time: { timeStyle: "medium" },
    yearMonth: { year: "numeric", month: "long" },
  },
};

export function Intl(
  props: Omit<ComponentProps<typeof NextIntlProvider>, "formats">
): JSX.Element {
  return <NextIntlProvider {...props} formats={formats} />;
}
