import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiDialogTitle: NonNullable<Components["MuiDialogTitle"]> = {
  styleOverrides: {
    root: {
      borderBottom: baseTheme.shape.solidBorder,
      [baseTheme.breakpoints.up("md")]: {
        paddingLeft: baseTheme.spacing(3),
        paddingRight: baseTheme.spacing(3),
        paddingTop: baseTheme.spacing(2),
        paddingBottom: baseTheme.spacing(2),
      },
      paddingLeft: baseTheme.spacing(2),
      paddingRight: baseTheme.spacing(2),
      paddingTop: baseTheme.spacing(3),
      paddingBottom: baseTheme.spacing(3),
      ...(baseTheme.typography.h3 as Record<string, string>),
    },
  },
};
