import { alpha, Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiTooltip: NonNullable<Components["MuiTooltip"]> = {
  styleOverrides: {
    tooltip: {
      backgroundColor: alpha(baseTheme.palette.common.black, 0.75),
      padding: baseTheme.spacing(0.5),
      color: baseTheme.palette.common.white,
      fontSize: "0.75rem",
      lineHeight: 1,
      fontWeight: "normal",
      borderRadius: "4px",
    },
  },
};
