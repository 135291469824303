import { useRouter } from "next/router";
import { Typography, Button, Stack } from "@mui/material";
import { useTranslations } from "next-intl";

import { ActiveSession } from "src/components/ColoredIcons/ActiveSession";
import { Dialog } from "src/components/Dialog";

export function ActiveSessionDialog(): JSX.Element {
  const t = useTranslations("ACTIVE_SESSION_VIEW");
  const { query, replace, pathname } = useRouter();

  function onCloseDialog() {
    replace(pathname, undefined, { shallow: true });
  }

  const showDialog = query.redirectReason === "auth";
  if (!showDialog) {
    return <></>;
  }

  return (
    <Dialog isOpen>
      <Stack spacing={2} alignItems="center">
        <ActiveSession />
        <Stack spacing={1}>
          <Typography textAlign="center" variant="h1">
            {t("title")}
          </Typography>
          <Typography textAlign="center" variant="body2" color="text.hint">
            {t("subtitle")}
          </Typography>
        </Stack>
        <Button sx={{ maxWidth: "15.625rem" }} onClick={onCloseDialog}>
          {t("cta.done")}
        </Button>
      </Stack>
    </Dialog>
  );
}
