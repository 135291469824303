import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiCardContent: NonNullable<Components["MuiCardContent"]> = {
  styleOverrides: {
    root: {
      [baseTheme.breakpoints.up("md")]: {
        padding: baseTheme.spacing(2),
      },
      padding: baseTheme.spacing(2),
      "&:last-of-type": {
        paddingBottom: baseTheme.spacing(2),
      },
    },
  },
};
