import { Typography, Button, Stack } from "@mui/material";
import { useTranslations } from "next-intl";

import { Dialog } from "src/components/Dialog";
import { InvalidLink } from "src/components/ColoredIcons/InvalidLink";

export interface INotFoundDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function NotFoundDialog({
  isOpen,
  onClose,
}: INotFoundDialogProps): JSX.Element {
  const t = useTranslations("NOT_FOUND_VIEW.dialog");

  return (
    <Dialog isOpen={isOpen} data-testid="NotFound-Modal">
      <Stack spacing={2} alignItems="center">
        <InvalidLink />
        <Stack spacing={1} alignItems="center">
          <Typography variant="h1">{t("title")}</Typography>
          <Typography variant="body2">{t("description")}</Typography>
        </Stack>
        <Button sx={{ width: "15.625rem" }} onClick={onClose}>
          {t("cta.gotIt")}
        </Button>
      </Stack>
    </Dialog>
  );
}
