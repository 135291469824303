import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiBreadcrumbs: NonNullable<Components["MuiBreadcrumbs"]> = {
  styleOverrides: {
    root: {
      "& .MuiLink-root": {
        fontSize: "0.75rem",
        fontWeight: 500,
        color: baseTheme.palette.text.secondary,
      },
    },
  },
};
