import { Components } from "@mui/material";

export const MuiCssBaseline: NonNullable<Components["MuiCssBaseline"]> = {
  styleOverrides: {
    dl: {
      margin: 0,
    },
    dt: {
      margin: 0,
    },
    dd: {
      margin: 0,
    },
    legend: {
      padding: 0,
    },
  },
};
