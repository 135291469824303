import {
  Dialog as MuiDialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
} from "@mui/material";

import { Icon, EIcon } from "src/components/Icon";
import { useIsMobile } from "src/hooks/useIsMobile";

export interface IBaseDialogProps
  extends Omit<DialogProps, "open" | "onClose"> {
  isOpen: boolean;
  onClose?: () => void;
  disableMobileFullscreen?: boolean;
  disableOnClickOutside?: boolean;
}

export function BaseDialog({
  isOpen,
  onClose,
  children,
  disableMobileFullscreen,
  disableOnClickOutside,
  ...props
}: IBaseDialogProps): JSX.Element {
  const isMobile = useIsMobile();

  const handleClose: DialogProps["onClose"] =
    onClose &&
    ((_e, reason) => {
      if (disableOnClickOutside && reason === "backdropClick") {
        return;
      }
      onClose();
    });

  return (
    <MuiDialog
      open={isOpen}
      onClose={handleClose}
      fullScreen={isMobile && !disableMobileFullscreen}
      {...props}
    >
      {children}

      {onClose && (
        <Grid item>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              mr: { xs: 1, md: 1.7 },
              mt: { xs: 3, md: 2 },
            }}
            onClick={onClose}
          >
            <Icon icon={EIcon.CLOSE} />
          </IconButton>
        </Grid>
      )}
    </MuiDialog>
  );
}

interface IDialogProps extends IBaseDialogProps {
  title?: string;
  dividerRequired?: boolean;
}

export function Dialog({
  title,
  children,
  dividerRequired = true,
  ...props
}: IDialogProps): JSX.Element {
  return (
    <BaseDialog {...props}>
      {title && (
        <DialogTitle
          sx={{
            border: dividerRequired ? undefined : 0,
            fontWeight: dividerRequired ? undefined : "bold",
          }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </BaseDialog>
  );
}
