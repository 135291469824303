import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { IPortalConfigResponse } from "types/portalConfig/IPortalConfigResponse";

interface ITransformedPortalConfigResponse extends IPortalConfigResponse {
  selfServicingLinksEnabled: boolean;
  isTillDomain: boolean;
}

const TILL_DOMAIN_PATTERN = /^portal.till(vision|payments).(dev|show|com)$/;

function usePortalConfig(): UseQueryResult<ITransformedPortalConfigResponse> {
  return useQuery(["/portal/config"], {
    refetchOnWindowFocus: false,
    select: (data: ITransformedPortalConfigResponse) => {
      const selfServicingLinksEnabled =
        !!data?.customUrls?.selfServicingLinks &&
        typeof data?.customUrls?.selfServicingLinks === "object" &&
        Object.keys(data?.customUrls?.selfServicingLinks).length > 0;
      const isTillDomain =
        !!data?.domain && TILL_DOMAIN_PATTERN.test(data.domain);
      return {
        ...data,
        selfServicingLinksEnabled,
        isTillDomain,
      };
    },
  });
}

export default usePortalConfig;
