import {
  RichTranslationValues,
  TranslationValues,
  useTranslations,
} from "next-intl";
import { ReactNode } from "react";

import usePortalConfig from "../usePortalConfig";

export function useTillTranslations(translationKey?: string) {
  const t = useTranslations(translationKey);
  const { data: portalConfig } = usePortalConfig();
  const companyName = portalConfig?.entityName ?? "";

  return {
    t: (
      messageKey: string | number | symbol | ReactNode,
      args?: TranslationValues | undefined
    ) => {
      const result: TranslationValues | undefined = {};
      result.companyName = portalConfig?.entityName ?? "";

      if (args) {
        Object.keys(args).forEach((key) => {
          result[key] = args[key];
        });
      }
      if (Object.keys(result).length) {
        return t(messageKey, { ...result });
      }
      return t(messageKey);
    },
    rich: (
      messageKey: string | number | symbol | ReactNode,
      args?: RichTranslationValues | undefined
      /* eslint-disable @typescript-eslint/no-explicit-any */
    ): any => {
      const result: RichTranslationValues | undefined = {};
      result.companyName = companyName;

      if (args) {
        Object.keys(args).forEach((key) => {
          result[key] = args[key];
        });
      }
      if (Object.keys(result).length) {
        return t.rich(messageKey, { ...result });
      }
      return t.rich(messageKey);
    },
    raw: (messageKey: string | number | symbol | ReactNode) => {
      const result = t.raw(messageKey);

      const hasCompanyName =
        Array.isArray(result) &&
        result.length > 0 &&
        result.some(
          (item) => typeof item === "string" && item.includes("{companyName}")
        );

      if (hasCompanyName) {
        result.forEach((item, index) => {
          result[index] = item.replace("{companyName}", companyName);
        });
      }

      return result;
    },
  };
}
