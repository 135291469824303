/* eslint max-lines: [1, { "max": 350, "skipBlankLines": false, "skipComments": false }] */
export type EHttpCode =
  | 100
  | 101
  | 102
  | 103
  | 200
  | 201
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 226
  | 300
  | 301
  | 302
  | 303
  | 304
  | 305
  | 307
  | 308
  | 400
  | 401
  | 402
  | 403
  | 404
  | 405
  | 406
  | 407
  | 408
  | 409
  | 410
  | 411
  | 412
  | 413
  | 414
  | 415
  | 416
  | 417
  | 418
  | 421
  | 422
  | 423
  | 424
  | 425
  | 426
  | 428
  | 429
  | 431
  | 451
  | 500
  | 501
  | 502
  | 503
  | 504
  | 505
  | 506
  | 507
  | 508
  | 509
  | 510
  | 511;

export const identifierByCodes: Record<EHttpCode, string> = {
  100: "Continue",
  101: "SwitchingProtocols",
  102: "Processing",
  103: "EarlyHints",
  200: "OK",
  201: "Created",
  202: "Accepted",
  203: "NonAuthoritativeInformation",
  204: "NoContent",
  205: "ResetContent",
  206: "PartialContent",
  207: "MultiStatus",
  208: "AlreadyReported",
  226: "IMUsed",
  300: "MultipleChoices",
  301: "MovedPermanently",
  302: "Found",
  303: "SeeOther",
  304: "NotModified",
  305: "UseProxy",
  307: "TemporaryRedirect",
  308: "PermanentRedirect",
  400: "BadRequest",
  401: "Unauthorized",
  402: "PaymentRequired",
  403: "Forbidden",
  404: "NotFound",
  405: "MethodNotAllowed",
  406: "NotAcceptable",
  407: "ProxyAuthenticationRequired",
  408: "RequestTimeout",
  409: "Conflict",
  410: "Gone",
  411: "LengthRequired",
  412: "PreconditionFailed",
  413: "PayloadTooLarge",
  414: "URITooLong",
  415: "UnsupportedMediaType",
  416: "RangeNotSatisfiable",
  417: "ExpectationFailed",
  418: "ImaTeapot",
  421: "MisdirectedRequest",
  422: "UnprocessableEntity",
  423: "Locked",
  424: "FailedDependency",
  425: "TooEarly",
  426: "UpgradeRequired",
  428: "PreconditionRequired",
  429: "TooManyRequests",
  431: "RequestHeaderFieldsTooLarge",
  451: "UnavailableForLegalReasons",
  500: "InternalServerError",
  501: "NotImplemented",
  502: "BadGateway",
  503: "ServiceUnavailable",
  504: "GatewayTimeout",
  505: "HTTPVersionNotSupported",
  506: "VariantAlsoNegotiates",
  507: "InsufficientStorage",
  508: "LoopDetected",
  509: "BandwidthLimitExceeded",
  510: "NotExtended",
  511: "NetworkAuthenticationRequired",
};

export const codesByIdentifier: Record<string, EHttpCode> = {
  Continue: 100,
  SwitchingProtocols: 101,
  Processing: 102,
  EarlyHints: 103,
  OK: 200,
  Created: 201,
  Accepted: 202,
  NonAuthoritativeInformation: 203,
  NoContent: 204,
  ResetContent: 205,
  PartialContent: 206,
  MultiStatus: 207,
  AlreadyReported: 208,
  IMUsed: 226,
  MultipleChoices: 300,
  MovedPermanently: 301,
  Found: 302,
  SeeOther: 303,
  NotModified: 304,
  UseProxy: 305,
  TemporaryRedirect: 307,
  PermanentRedirect: 308,
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  ProxyAuthenticationRequired: 407,
  RequestTimeout: 408,
  Conflict: 409,
  Gone: 410,
  LengthRequired: 411,
  PreconditionFailed: 412,
  PayloadTooLarge: 413,
  URITooLong: 414,
  UnsupportedMediaType: 415,
  RangeNotSatisfiable: 416,
  ExpectationFailed: 417,
  ImaTeapot: 418,
  MisdirectedRequest: 421,
  UnprocessableEntity: 422,
  Locked: 423,
  FailedDependency: 424,
  TooEarly: 425,
  UpgradeRequired: 426,
  PreconditionRequired: 428,
  TooManyRequests: 429,
  RequestHeaderFieldsTooLarge: 431,
  UnavailableForLegalReasons: 451,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  HTTPVersionNotSupported: 505,
  VariantAlsoNegotiates: 506,
  InsufficientStorage: 507,
  LoopDetected: 508,
  BandwidthLimitExceeded: 509,
  NotExtended: 510,
  NetworkAuthenticationRequired: 511,
};

export const messagesByCodes: Record<EHttpCode, string> = {
  100: "Continue",
  101: "Switching Protocols",
  102: "Processing",
  103: "Early Hints",
  200: "OK",
  201: "Created",
  202: "Accepted",
  203: "Non-Authoritative Information",
  204: "No Content",
  205: "Reset Content",
  206: "Partial Content",
  207: "Multi-Status",
  208: "Already Reported",
  226: "IM Used",
  300: "Multiple Choices",
  301: "Moved Permanently",
  302: "Found",
  303: "See Other",
  304: "Not Modified",
  305: "Use Proxy",
  307: "Temporary Redirect",
  308: "Permanent Redirect",
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Payload Too Large",
  414: "URI Too Long",
  415: "Unsupported Media Type",
  416: "Range Not Satisfiable",
  417: "Expectation Failed",
  418: "I'm a Teapot",
  421: "Misdirected Request",
  422: "Unprocessable Entity",
  423: "Locked",
  424: "Failed Dependency",
  425: "Too Early",
  426: "Upgrade Required",
  428: "Precondition Required",
  429: "Too Many Requests",
  431: "Request Header Fields Too Large",
  451: "Unavailable For Legal Reasons",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  506: "Variant Also Negotiates",
  507: "Insufficient Storage",
  508: "Loop Detected",
  509: "Bandwidth Limit Exceeded",
  510: "Not Extended",
  511: "Network Authentication Required",
};

export const phrasesByCodes: Record<EHttpCode, string> = {
  100: "Continue.",
  101: "Switching protocols.",
  102: "Processing.",
  103: "Early hints.",
  200: "Ok.",
  201: "Created.",
  202: "Accepted.",
  203: "Non-authoritative information.",
  204: "No content.",
  205: "Reset content.",
  206: "Partial content.",
  207: "Multi-status.",
  208: "Already reported.",
  226: "Im used.",
  300: "Multiple choices.",
  301: "Moved permanently.",
  302: "Found.",
  303: "See other.",
  304: "Not modified.",
  305: "Use proxy.",
  307: "Temporary redirect.",
  308: "Permanent redirect.",
  400: "Bad request.",
  401: "Unauthorized.",
  402: "Payment required.",
  403: "Forbidden.",
  404: "Not found.",
  405: "Method not allowed.",
  406: "Not acceptable.",
  407: "Proxy authentication required.",
  408: "Request timeout.",
  409: "Conflict.",
  410: "Gone.",
  411: "Length required.",
  412: "Precondition failed.",
  413: "Payload too large.",
  414: "Uri too long.",
  415: "Unsupported media type.",
  416: "Range not satisfiable.",
  417: "Expectation failed.",
  418: "I'm a teapot.",
  421: "Misdirected request.",
  422: "Unprocessable entity.",
  423: "Locked.",
  424: "Failed dependency.",
  425: "Too early.",
  426: "Upgrade required.",
  428: "Precondition required.",
  429: "Too many requests.",
  431: "Request header fields too large.",
  451: "Unavailable for legal reasons.",
  500: "Internal server error.",
  501: "Not implemented.",
  502: "Bad gateway.",
  503: "Service unavailable.",
  504: "Gateway timeout.",
  505: "Http version not supported.",
  506: "Variant also negotiates.",
  507: "Insufficient storage.",
  508: "Loop detected.",
  509: "Bandwidth limit exceeded.",
  510: "Not extended.",
  511: "Network authentication required.",
};

export const CONTINUE: EHttpCode = 100;
export const SWITCHING_PROTOCOLS: EHttpCode = 101;
export const PROCESSING: EHttpCode = 102;
export const EARLY_HINTS: EHttpCode = 103;
export const OK: EHttpCode = 200;
export const CREATED: EHttpCode = 201;
export const ACCEPTED: EHttpCode = 202;
export const NON_AUTHORITATIVE_INFORMATION: EHttpCode = 203;
export const NO_CONTENT: EHttpCode = 204;
export const RESET_CONTENT: EHttpCode = 205;
export const PARTIAL_CONTENT: EHttpCode = 206;
export const MULTI_STATUS: EHttpCode = 207;
export const ALREADY_REPORTED: EHttpCode = 208;
export const IMUSED: EHttpCode = 226;
export const MULTIPLE_CHOICES: EHttpCode = 300;
export const MOVED_PERMANENTLY: EHttpCode = 301;
export const FOUND: EHttpCode = 302;
export const SEE_OTHER: EHttpCode = 303;
export const NOT_MODIFIED: EHttpCode = 304;
export const USE_PROXY: EHttpCode = 305;
export const TEMPORARY_REDIRECT: EHttpCode = 307;
export const PERMANENT_REDIRECT: EHttpCode = 308;
export const BAD_REQUEST: EHttpCode = 400;
export const UNAUTHORIZED: EHttpCode = 401;
export const PAYMENT_REQUIRED: EHttpCode = 402;
export const FORBIDDEN: EHttpCode = 403;
export const NOT_FOUND: EHttpCode = 404;
export const METHOD_NOT_ALLOWED: EHttpCode = 405;
export const NOT_ACCEPTABLE: EHttpCode = 406;
export const PROXY_AUTHENTICATION_REQUIRED: EHttpCode = 407;
export const REQUEST_TIMEOUT: EHttpCode = 408;
export const CONFLICT: EHttpCode = 409;
export const GONE: EHttpCode = 410;
export const LENGTH_REQUIRED: EHttpCode = 411;
export const PRECONDITION_FAILED: EHttpCode = 412;
export const PAYLOAD_TOO_LARGE: EHttpCode = 413;
export const URITOO_LONG: EHttpCode = 414;
export const UNSUPPORTED_MEDIA_TYPE: EHttpCode = 415;
export const RANGE_NOT_SATISFIABLE: EHttpCode = 416;
export const EXPECTATION_FAILED: EHttpCode = 417;
export const IMA_TEAPOT: EHttpCode = 418;
export const MISDIRECTED_REQUEST: EHttpCode = 421;
export const UNPROCESSABLE_ENTITY: EHttpCode = 422;
export const LOCKED: EHttpCode = 423;
export const FAILED_DEPENDENCY: EHttpCode = 424;
export const TOO_EARLY: EHttpCode = 425;
export const UPGRADE_REQUIRED: EHttpCode = 426;
export const PRECONDITION_REQUIRED: EHttpCode = 428;
export const TOO_MANY_REQUESTS: EHttpCode = 429;
export const REQUEST_HEADER_FIELDS_TOO_LARGE: EHttpCode = 431;
export const UNAVAILABLE_FOR_LEGAL_REASONS: EHttpCode = 451;
export const INTERNAL_SERVER_ERROR: EHttpCode = 500;
export const NOT_IMPLEMENTED: EHttpCode = 501;
export const BAD_GATEWAY: EHttpCode = 502;
export const SERVICE_UNAVAILABLE: EHttpCode = 503;
export const GATEWAY_TIMEOUT: EHttpCode = 504;
export const HTTPVERSION_NOT_SUPPORTED: EHttpCode = 505;
export const VARIANT_ALSO_NEGOTIATES: EHttpCode = 506;
export const INSUFFICIENT_STORAGE: EHttpCode = 507;
export const LOOP_DETECTED: EHttpCode = 508;
export const BANDWIDTH_LIMIT_EXCEEDED: EHttpCode = 509;
export const NOT_EXTENDED: EHttpCode = 510;
export const NETWORK_AUTHENTICATION_REQUIRED: EHttpCode = 511;
