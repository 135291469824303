import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";
import { EIcon, EIconSize, Icon } from "src/components/Icon";

export const MuiSelect: NonNullable<Components["MuiSelect"]> = {
  defaultProps: {
    IconComponent: (props) => (
      <div {...props}>
        <Icon icon={EIcon.CHEVRON_DOWN} size={EIconSize.SM} />
      </div>
    ),
    MenuProps: {
      color: "#222222",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },

      variant: "menu",
      BackdropProps: { sx: { bgcolor: "transparent" } },
      MenuListProps: {
        disablePadding: true,
      },
    },
  },
  styleOverrides: {
    select: {
      paddingTop: "0.625rem",
      paddingBottom: "0.6875rem",
    },
    outlined: {
      "&.MuiSelect-outlined": {
        borderRadius: "2px",
        paddingRight: "3.25rem",

        "&.MuiInputBase-inputAdornedStart": {
          paddingLeft: "0.875rem",
          marginLeft: "-0.875rem",
        },

        "&.Mui-disabled ~ .MuiOutlinedInput-notchedOutline": {
          borderColor: baseTheme.palette.action.disabled,
        },
      },
    },
    iconOutlined: {
      top: "calc(50% - 0.55rem)",
      right: "1.125rem",
      "& .MuiBox-root": {
        width: "1.25rem",
        height: "1.25rem",
      },
    },
  },
};
