import { Components } from "@mui/material";

import { baseTheme } from "../base";

export const MuiAppBar: NonNullable<Components["MuiAppBar"]> = {
  defaultProps: {
    position: "sticky",
  },
  styleOverrides: {
    root: {
      boxShadow: "none",
      background: baseTheme.palette.common.white,
      color: baseTheme.palette.common.black,
    },
  },
};
