import { Components, getAlertUtilityClass } from "@mui/material";

import { baseTheme } from "src/theme/base";

const standard = getAlertUtilityClass("standard");
const standardError = getAlertUtilityClass("standardError");
const standardWarning = getAlertUtilityClass("standardWarning");
const standardSuccess = getAlertUtilityClass("standardSuccess");
const standardInfo = getAlertUtilityClass("standardInfo");
const icon = getAlertUtilityClass("icon");
const action = getAlertUtilityClass("action");
const message = getAlertUtilityClass("message");
const withCta = getAlertUtilityClass("withCta");

export const MuiAlert: NonNullable<Components["MuiAlert"]> = {
  defaultProps: {
    variant: "standard",
  },
  styleOverrides: {
    root: {
      color: baseTheme.palette.text.primary,
      backgroundColor: baseTheme.palette.background.shaded,
      borderRadius: "2px",
      [`& .${icon} .MuiSvgIcon-root`]: {
        width: baseTheme.spacing(1.8),
        height: baseTheme.spacing(1.8),
      },

      [`&.${standard} .${message}`]: {
        display: "flex",
        alignItems: "center",
        padding: 0,
        [baseTheme.breakpoints.down("md")]: {
          display: "block",
          flex: 1,
        },
      },

      [`&.${standardError}`]: {
        backgroundColor: baseTheme.palette.error.light,
      },

      [`&.${standardWarning}`]: {
        backgroundColor: baseTheme.palette.warning.light,
      },

      [`&.${standardSuccess}`]: {
        backgroundColor: baseTheme.palette.success.light,
      },

      [`&.${standardInfo}`]: {
        backgroundColor: baseTheme.palette.info.light,
      },

      [`& .${action}`]: {
        alignItems: "center",
        paddingTop: 0,
        [baseTheme.breakpoints.down("md")]: {
          alignItems: "flex-start",
          paddingBottom: baseTheme.spacing(1),
        },
      },
      [`&.${withCta}`]: {
        flexWrap: "nowrap",
        [baseTheme.breakpoints.down("md")]: {
          flexWrap: "wrap",
        },
      },
      [`&.${withCta} .${action}`]: {
        [baseTheme.breakpoints.down("md")]: {
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
        },
      },
    },
  },
};
