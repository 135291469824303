import type { MutationFunction } from "@tanstack/react-query";
import FormData from "form-data";

import { buildPath, defaultHost } from "./api";
import { sanitizeRequestBody } from "./sanitizeRequestBody";
import { getGatewayUrl } from "./getGatewayUrl";

import { HttpError, EHttpCode } from "src/utilities/HttpError";

export function mutateToPath<
  TData,
  TVariables,
  TTransformedVariables = TVariables
>(
  path: string,
  {
    host = defaultHost,
    method = "POST",
    sanitize = true,
    contentType = "application/json",
    sessionId,
    transformBody,
  }: {
    host?: string;
    method?: "POST" | "PATCH" | "PUT" | "DELETE";
    sanitize?: boolean;
    contentType?: string;
    sessionId?: string;
    transformBody?: (data: TVariables) => TTransformedVariables;
  } = {}
): MutationFunction<TData, TVariables> {
  return async function mutation(body: TVariables) {
    // window.location.origin is undefined if the code is running in the serverSideProps
    if (!host.includes("localhost") && typeof window !== "undefined") {
      const gatewayUrl = await getGatewayUrl(window.location.origin);
      // eslint-disable-next-line no-param-reassign
      host = gatewayUrl;
    }

    const url = new URL(host);

    url.pathname = buildPath(path, host);

    const headers = new Headers();

    let transformedBody: TVariables | TTransformedVariables = body;

    if (transformBody) {
      transformedBody = transformBody(body);
    } else if (sanitize) {
      transformedBody = sanitizeRequestBody(body);
    }

    let bodyToSend: string | FormData;

    if (transformedBody instanceof FormData) {
      // fetch will auto assign an accurate content-type with FormData
      bodyToSend = transformedBody;
    } else {
      bodyToSend = JSON.stringify(transformedBody);
      if (contentType) {
        headers.set("content-type", contentType);
      }
      if (sessionId) {
        // Next.js node server won't populate the cookie header automatically
        // no matter how we set the `credentials` prop of the request,
        // so we need to add `sessionId` in the cookie header by ourselves.
        // If `mutateToPath` is run from the browser, and due to
        // `credentials: "include"` the browser will attach appropriate
        // cookies to the cookie header of the request made, in which case
        // we don't need to pass down `sessionId` to `mutateToPath`
        headers.set("cookie", `sessionId=${sessionId}`);
      }
    }

    const req = new Request(url.toString(), {
      method,
      credentials: "include",
      headers,
      body: bodyToSend as RequestInit as ReadableStream<Uint8Array>,
    });

    const response = await fetch(req);

    if (!response.ok) {
      throw new HttpError(response.status as EHttpCode, await response.json());
    }

    switch (response.headers.get("content-type")?.split(";")[0]) {
      case "application/json":
        return response.json();
      default:
        return response.text();
    }
  };
}
