import { Pagination as MuiPagination } from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { MouseEvent } from "react";

import { useIsMobile } from "src/hooks/useIsMobile";

function Pagination({
  page,
  count,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps): JSX.Element {
  const isMobile = useIsMobile();
  const totalPages = Math.ceil(count / rowsPerPage);
  const hideNextPrevButton = totalPages === 1;

  return (
    <MuiPagination
      count={totalPages}
      page={page + 1}
      onChange={(event, newPage) =>
        onPageChange(
          event as unknown as MouseEvent<HTMLButtonElement>,
          newPage - 1
        )
      }
      hideNextButton={hideNextPrevButton}
      hidePrevButton={hideNextPrevButton}
      siblingCount={isMobile ? 0 : 1}
      sx={(theme) => ({
        [theme.breakpoints.down("md")]: {
          marginTop: 2,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        },
      })}
    />
  );
}

export default Pagination;
