import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiFormLabel: NonNullable<Components["MuiFormLabel"]> = {
  styleOverrides: {
    root: {
      color: baseTheme.palette.text.primary,
    },
  },
};
