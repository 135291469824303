import { BroadcastChannel } from "broadcast-channel";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { useLogout } from "src/hooks/auth/useLogout";

function createAuthChannel(): BroadcastChannel {
  return new BroadcastChannel("till-vision-broadcast-channel");
}

export async function handleLogout(): Promise<void> {
  const channel = createAuthChannel();
  await channel.postMessage("logout");
  await channel.close();
}

export function AuthBroadcastChannel(): JSX.Element {
  const { push } = useRouter();
  const { mutate: logoutMutation } = useLogout();

  useEffect(() => {
    const channel = createAuthChannel();

    channel.onmessage = (message) => {
      if (message === "logout") {
        logoutMutation({});
      }
    };
    return () => {
      channel.close();
    };
  }, [logoutMutation, push]);

  return <></>;
}
