import { Components } from "@mui/material";

import { baseTheme } from "../base";

import { EIcon, Icon } from "src/components/Icon";

export const MuiAccordionSummary: NonNullable<
  Components["MuiAccordionSummary"]
> = {
  defaultProps: {
    expandIcon: <Icon icon={EIcon.CHEVRON_DOWN_GREY} />,
  },
  styleOverrides: {
    root: {
      padding: 0,
    },
    content: {
      marginTop: baseTheme.spacing(2),
      marginBottom: baseTheme.spacing(2),
    },
  },
};
