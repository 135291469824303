import { Components, getDialogTitleUtilityClass } from "@mui/material";

import { baseTheme } from "src/theme/base";

const dialogTitleClass = getDialogTitleUtilityClass("root");
const titleLineHeight = "calc(1rem * 1.6)";

export const MuiDialogContent: NonNullable<Components["MuiDialogContent"]> = {
  styleOverrides: {
    root: {
      padding: baseTheme.spacing(2),
      "&:first-of-type": {
        paddingTop: baseTheme.spacing(2),
      },
      [baseTheme.breakpoints.up("md")]: {
        padding: baseTheme.spacing(3),
      },
      [baseTheme.breakpoints.down("md")]: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",

        height: "100%",
        // account for height of title when exists
        [`.${dialogTitleClass} + &`]: {
          height: `calc(100% - ${titleLineHeight} - ${baseTheme.spacing(6)})`,
        },
      },
    },
  },
};
