import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiPaginationItem: NonNullable<Components["MuiPaginationItem"]> = {
  styleOverrides: {
    root: {
      color: baseTheme.palette.tillGrey.dark,
    },
  },
};
