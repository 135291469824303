import { Components } from "@mui/material";

export const MuiAccordion: NonNullable<Components["MuiAccordion"]> = {
  styleOverrides: {
    root: {
      boxShadow: "none",
      background: "transparent",
      marginTop: 0,
      "&.MuiPaper-root": {
        marginTop: 0,
      },
    },
  },
};
