import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiTabs: NonNullable<Components["MuiTabs"]> = {
  styleOverrides: {
    root: {
      minHeight: 0,
      borderBottom: baseTheme.shape.solidBorder,
    },
    indicator: {
      backgroundColor: "primary",
      height: "3px",
    },
  },
};
