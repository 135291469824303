import { Components } from "@mui/material";

export const MuiListItemIcon: NonNullable<Components["MuiListItemIcon"]> = {
  styleOverrides: {
    root: {
      height: "1.125rem",
      minWidth: "2rem",
    },
  },
};
