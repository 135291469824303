import { Components, backdropClasses, alpha } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiDrawer: NonNullable<Components["MuiDrawer"]> = {
  styleOverrides: {
    modal: {
      [`.${backdropClasses.root}`]: {
        backgroundColor: alpha(baseTheme.palette.background.default, 0.7),
      },
    },
  },
};
