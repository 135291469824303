import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";
import Pagination from "src/components/Table/Pagination";

export const MuiTablePagination: NonNullable<Components["MuiTablePagination"]> =
  {
    defaultProps: {
      labelDisplayedRows: () => null,
      ActionsComponent: Pagination,
      labelRowsPerPage: "Records per page:", // TODO: i18n
      rowsPerPageOptions: [10, 25, 50, 100],
    },
    styleOverrides: {
      toolbar: {
        flexWrap: "wrap",
        justifyContent: "flex-end",
        [baseTheme.breakpoints.down("md")]: {
          justifyContent: "center",
        },
        [baseTheme.breakpoints.up("xs")]: {
          padding: 0,
        },
      },
      selectRoot: {
        fontSize: "1rem",
        [baseTheme.breakpoints.down("md")]: {
          marginRight: 0,
        },
      },
      select: {
        fontSize: "0.875rem",
        lineHeight: 1.43,
      },
    },
  };
