import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useRouter, Router } from "next/router";

import { mutateToPath } from "src/utilities/mutateFn";
import store from "src/utilities/store";

interface ILogoutBody {}

interface ILogoutResponse {
  success: boolean;
}

export function useLogout({
  clearStorage = true,
  destination = "/signin",
}: {
  clearStorage?: boolean;
  destination?: Parameters<Router["push"]>[0];
} = {}): UseMutationResult<ILogoutResponse, unknown, ILogoutBody> {
  const router = useRouter();
  const queryClient = useQueryClient();

  return useMutation(
    mutateToPath<ILogoutResponse, ILogoutBody>("/auth/user-session/logout"),
    {
      onError() {},
      async onSettled() {
        if (clearStorage) {
          store.sessionStore.remove("currentUserObj");
        }

        await queryClient.cancelQueries();
        queryClient.clear();
        await router.push(destination);
      },
    }
  );
}
