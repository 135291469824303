import { styled } from "@mui/material/styles";

const PREFIX = "ActiveSession";

const classes = {
  line: `${PREFIX}-line`,
  circle: `${PREFIX}-circle`,
  exclamation: `${PREFIX}-exclamation`,
};

const Root = styled("svg")(({ theme }) => {
  return {
    [`& .${classes.line}`]: {
      fill: "none",
      stroke: theme.palette.common.black,
      strokeWidth: 4,
    },
    [`& .${classes.exclamation}`]: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      fill: theme.palette.error.main,
    },
  };
});

export function ActiveSession(): JSX.Element {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="88px"
      height="88px"
      viewBox="0 0 88 88"
      version="1.1"
    >
      <path
        className={classes.line}
        d="M28,64c0-9.3,5.3-14,16-14s16,4.7,16,14"
      />
      <circle className={classes.line} cx="44" cy="37" r="13" />
      <path
        className={classes.line}
        d="M83.9,57.1C85.3,53,86,48.6,86,44C86,20.8,67.2,2,44,2S2,20.8,2,44s18.8,42,42,42c4.5,0,8.9-0.7,13-2"
      />
      <path
        className={classes.exclamation}
        d="M74,60c7.7,0,14,6.3,14,14c0,7.7-6.3,14-14,14c-7.7,0-14-6.3-14-14C60,66.3,66.3,60,74,60z M74,78c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S75.1,78,74,78z M74,66c-1.1,0-2,0.9-2,2l0,0v6c0,1.1,0.9,2,2,2s2-0.9,2-2l0,0v-6 C76,66.9,75.1,66,74,66z"
      />
    </Root>
  );
}
