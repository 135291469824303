/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import {
  Box,
  Container,
  Stack,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useMemo } from "react";
import { useTranslations } from "next-intl";

import { Layout } from "..";
import type { ILayoutProps } from "..";

import baseTheme from "src/theme";
import usePortalConfig from "src/hooks/usePortalConfig";
import { EIcon } from "src/components/Icon";

const publicTheme = createTheme(baseTheme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          maxWidth: "17.5rem",
          width: "100%",
        },
      },
    },
  },
});

export function ErrorLayout({ children, title }: ILayoutProps): JSX.Element {
  const t = useTranslations();
  const { data: portalConfig, isFetched } = usePortalConfig();

  const theme = useMemo(
    () =>
      isFetched && portalConfig?.themeColors
        ? createTheme(publicTheme, { palette: portalConfig.themeColors })
        : publicTheme,
    [portalConfig?.themeColors, isFetched, publicTheme]
  );

  const localizedTitle = t(title);

  return (
    <ThemeProvider theme={theme}>
      <Layout title={localizedTitle}>
        <Container data-testid="layout-error" sx={{ pb: 1 }}>
          <Box
            component="img"
            src="/background/publicHeader.webp"
            alt=""
            sx={{
              top: 0,
              width: "100vw",
              height: "auto",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: -1,
            }}
          />
          <Stack direction="column" spacing={8} justifyContent="center">
            <Container
              maxWidth="md"
              sx={{
                position: "relative",
                justifyContent: "center",
                paddingTop: 12,
                textAlign: "center",
                px: { xs: 0, lg: undefined },
              }}
            >
              <Box
                component="img"
                src="/background/publicContent.webp"
                alt=""
                sx={{
                  display: "flex",
                  overflowX: "hidden",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "-8.5rem",
                  zIndex: -1,
                }}
              />
              <Stack spacing={3} justifyContent="center" alignItems="center">
                {isFetched && (
                  <Image
                    src={portalConfig?.logoUrls?.logo ?? EIcon.TILL_LOGO}
                    width="92"
                    height="42"
                    alt=""
                  />
                )}
                {children}
              </Stack>
            </Container>
          </Stack>
        </Container>
      </Layout>
    </ThemeProvider>
  );
}

export default ErrorLayout;
