import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiOutlinedInput: NonNullable<Components["MuiOutlinedInput"]> = {
  defaultProps: {
    color: "secondary",
    fullWidth: true,
  },
  styleOverrides: {
    root: {
      borderRadius: "2px",
      backgroundColor: baseTheme.palette.common.white,
      "&.Mui-disabled": {
        backgroundColor: baseTheme.palette.background.shaded,
        color: `${baseTheme.palette.text.primary} !important`,
        // The line below causes Range error in tests
        // "-webkit-text-fill-color": null,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: 1,
      },
    },
    input: {
      "&:not(textarea)": {
        padding: baseTheme.spacing(1.6, 2),
      },
    },
    notchedOutline: { borderColor: baseTheme.palette.divider },
  },
};
