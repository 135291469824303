import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiListItem: NonNullable<Components["MuiListItem"]> = {
  styleOverrides: {
    root: {
      paddingTop: `calc(${baseTheme.spacing(1)} / 2)`,
      paddingBottom: `calc(${baseTheme.spacing(1)} / 2)`,
    },
  },
};
