import {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
} from "react";
import { useRouter } from "next/router";

import InternalError from "pages/500.page";
import { NotFoundDialog } from "src/components/NotFoundDialog";

interface IErrorContext {
  showError: Dispatch<boolean>;
  showNotFound: Dispatch<boolean>;
}

export const ErrorContext = createContext<IErrorContext>({
  showError: () => {},
  showNotFound: () => {},
});

interface IErrorProviderProps {
  showError: boolean;
  showNotFound: boolean;
  children: ReactNode;
}

export function ErrorProvider({
  showError = false,
  showNotFound = false,
  children,
}: IErrorProviderProps): JSX.Element {
  const [isErrorOpen, setIsErrorOpen] = useState(showError);
  const [isNotFoundOpen, setIsNotFoundOpen] = useState(showNotFound);

  const router = useRouter();
  router.events?.on("routeChangeComplete", () => {
    setIsErrorOpen(false);
    setIsNotFoundOpen(false);
  });

  return (
    <ErrorContext.Provider
      value={{ showError: setIsErrorOpen, showNotFound: setIsNotFoundOpen }}
    >
      {isErrorOpen &&
        InternalError.getLayout(
          <div style={{ width: "100%", height: "100%" }}>
            <InternalError />
          </div>
        )}
      {!isErrorOpen && (
        <>
          {children}
          <NotFoundDialog
            isOpen={isNotFoundOpen}
            onClose={() => setIsNotFoundOpen(false)}
          />
        </>
      )}
    </ErrorContext.Provider>
  );
}

export function useShowError(): Dispatch<boolean> {
  return useContext(ErrorContext).showError;
}

export function useShowNotFound(): Dispatch<boolean> {
  return useContext(ErrorContext).showNotFound;
}
