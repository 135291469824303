import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiFormControlLabel: NonNullable<
  Components["MuiFormControlLabel"]
> = {
  styleOverrides: {
    root: {
      color: baseTheme.palette.text.hint,
      marginRight: 0,
    },
  },
};
