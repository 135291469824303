import { Components, dialogClasses } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiDialog: NonNullable<Components["MuiDialog"]> = {
  defaultProps: {
    scroll: "body",
    maxWidth: "sm",
    fullWidth: true,
  },
  styleOverrides: {
    paperWidthSm: {
      maxWidth: "5rem",
    },
    paper: {
      borderRadius: "0.375rem",
      [`&.${dialogClasses.paperWidthSm}`]: {
        maxWidth: `${baseTheme.breakpoints.values.md}rem`,
      },

      [baseTheme.breakpoints.down("xs")]: {
        margin: baseTheme.spacing(2),
        [`&.${dialogClasses.paperFullWidth}`]: {
          width: `calc(100% - ${baseTheme.spacing(4)})`,
        },
        [`&.${dialogClasses.paperWidthSm}.${dialogClasses.paperScrollBody}`]: {
          maxWidth: `calc(100% - ${baseTheme.spacing(4)})`,
        },
      },
    },
  },
};
