import { Components } from "@mui/material";

export const MuiAccordionDetails: NonNullable<
  Components["MuiAccordionDetails"]
> = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};
