import { Components } from "@mui/material";

export const MuiIcon: NonNullable<Components["MuiIcon"]> = {
  styleOverrides: {
    root: {
      width: "1.125rem",
      height: "1.125rem",
    },
  },
};
