import { Components } from "@mui/material";

export const MuiDivider: NonNullable<Components["MuiDivider"]> = {
  defaultProps: {
    light: true,
  },
  styleOverrides: {
    root: {
      borderColor: "#DDDDDD",
    },
    light: {
      borderColor: "#EEEEEE",
    },
  },
};
