import { alpha, Components, getToggleButtonUtilityClass } from "@mui/material";

import { baseTheme } from "src/theme/base";

const selectClass = getToggleButtonUtilityClass("selected");

export const MuiToggleButton: NonNullable<Components["MuiToggleButton"]> = {
  styleOverrides: {
    root: {
      padding: baseTheme.spacing(0.5, 1),
      color: baseTheme.palette.common.black,
      fontSize: "0.75rem",
      lineHeight: 1,
      fontWeight: "normal",
      borderRadius: "0.25rem",
      textTransform: "none",
      [`&.${selectClass}`]: {
        backgroundColor: baseTheme.palette.common.black,
        color: baseTheme.palette.common.white,
        border: 0,
      },
      [`&:hover, &.${selectClass}:hover`]: {
        backgroundColor: alpha(baseTheme.palette.common.black, 0.15),
        borderColor: alpha(baseTheme.palette.common.black, 0.15),
        color: baseTheme.palette.common.black,
      },
    },
  },
};
