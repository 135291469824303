import type { QueryFunctionContext } from "@tanstack/react-query";

import { getGatewayUrl } from "./getGatewayUrl";

import { HttpError, EHttpCode } from "src/utilities/HttpError";
import { ensureArray } from "src/utilities/ensureArray";
import { defaultHost, buildPath } from "src/utilities/api";

interface IQueryKey {
  query?: Record<string, string | string[]>;
  fragment?: string;

  // used for useInfinateQuery
  paginationKey?: "pn" | "pageNumber";
}

export interface IBuildQueryFnProps {
  responseCb?: (response: Response) => void;
  headers?: Record<string, string>;
  host?: string;
  isPageRoute?: boolean;
}

function isQueryObject(input: unknown): input is IQueryKey {
  return typeof input === "object";
}

export function buildQueryFn<R>({
  responseCb,
  headers = {},
  host = defaultHost,
  isPageRoute,
}: IBuildQueryFnProps = {}) {
  return async function queryFn({
    queryKey: [path, config],

    // used for useInfinateQuery
    pageParam,
  }: QueryFunctionContext): Promise<R> {
    if (!isPageRoute && !host.includes("localhost")) {
      if (typeof window !== "undefined") {
        const gatewayUrl = await getGatewayUrl(window.location.origin);
        // eslint-disable-next-line no-param-reassign
        host = gatewayUrl;
      }
    }

    const url = new URL(host);
    const [pathname, search] = (path as string).split("?");

    url.pathname = buildPath(pathname, host);

    if (search) {
      url.search = search;
    }

    if (isQueryObject(config)) {
      if (config.query != null) {
        Object.entries(config.query).forEach(([key, values]) => {
          ensureArray<string>(values).forEach((value) => {
            url.searchParams.append(key, value);
          });
        });
      }

      if (config.fragment != null) {
        url.hash = `#${config.fragment}`;
      }

      if (pageParam) {
        if (!config.paginationKey) {
          throw new Error("config.paginationKey needed");
        }
        url.searchParams.append(config.paginationKey, pageParam);
      }
    }

    if ("host" in headers) {
      // eslint-disable-next-line no-param-reassign
      delete headers.host;
    }

    const req = new Request(
      // Make sure we set the request url without domain in the url base, if the request should call nextjs server instead of gateway
      isPageRoute ? buildPath(path as string) : url.toString(),
      {
        credentials: "include",
        headers,
      }
    );

    const response = await fetch(req);

    responseCb?.(response);

    if (!response.ok) {
      throw new HttpError(response.status as EHttpCode, await response.json());
    }

    const contentType = response.headers.get("content-type")?.split(";")[0];

    switch (contentType) {
      // We need the header information and the body to download the files
      case "application/csv":
      case "application/zip":
        return response as unknown as R;
      default:
        return response.json();
    }
  };
}
