import { ThemeOptions } from "@mui/material";

export const breakpoints: NonNullable<ThemeOptions["breakpoints"]> = {
  values: {
    xs: 0,
    sm: 20, // 320
    md: 42.5, // 680
    lg: 60, // 960
    xl: 80, // 1280
    // xl: 120, // 1920
  },
  unit: "rem",
};
