// eslint-disable
import { NextApiRequest } from "next";

import { IPortalConfigResponse } from "types/portalConfig/IPortalConfigResponse";

const fetchConfig = async (referer: string) => {
  const url = new URL(`/portal/config`, process.env.REACT_APP_GATEWAY_URL);

  const passReq = new Request(url.toString(), {
    method: "GET",
    headers: {
      Referer: referer,
    },
  });
  const data = await fetch(passReq);
  const portalConfig: IPortalConfigResponse = await data.json();

  return portalConfig;
};

const isNextApiRequest = (
  input: NextApiRequest | string
): input is NextApiRequest => {
  return (input as NextApiRequest)?.headers !== undefined;
};

export const extractBaseDomain = (url: string) => {
  const { hostname } = new URL(url);
  const domainParts = hostname.split(".");

  if (domainParts.length > 2) {
    return domainParts.slice(-2).join(".");
  }

  return hostname;
};

export const getGatewayUrl = async (
  param: NextApiRequest | string,
  isPageRoute = false
): Promise<string> => {
  let referer: string;

  if (isNextApiRequest(param)) {
    referer = param.headers.referer as string;
  } else {
    referer = param;
  }

  if (referer?.includes("localhost")) {
    if (isPageRoute) {
      return process.env.REACT_APP_URL as string;
    }

    return process.env.REACT_APP_GATEWAY_URL as string;
  }

  const baseDomain = extractBaseDomain(referer);
  const tillBaseDomain = extractBaseDomain(
    process.env.REACT_APP_GATEWAY_URL as string
  );

  if (baseDomain === tillBaseDomain) {
    return process.env.REACT_APP_GATEWAY_URL as string;
  }

  const portalConfig = await fetchConfig(referer);

  if (!portalConfig?.gatewayUrl) {
    return process.env.REACT_APP_GATEWAY_URL as string;
  }

  const gatewayUrl = portalConfig?.gatewayUrl ?? "api-gateway-online-pa";

  return `https://${gatewayUrl}.${baseDomain}`;
};
