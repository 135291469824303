import { createContext, ReactNode, useRef } from "react";
import {
  QueryClientProvider,
  Hydrate,
  QueryClient,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useRouter } from "next/router";

import { useShowNotFound, useShowError } from "./Error";

import {
  UNAUTHORIZED,
  NOT_FOUND,
  INTERNAL_SERVER_ERROR,
} from "src/utilities/HttpError/statuses";
import { HttpError } from "src/utilities/HttpError";
import { buildQueryFn } from "src/utilities/buildQueryFn";

interface IReactQueryContext {}

export const ReactQueryContext = createContext<IReactQueryContext>({});

interface IReactQueryProviderProps {
  dehydratedState: Record<string, unknown>;
  children: ReactNode;
}

export function ReactQueryProvider({
  dehydratedState,
  children,
}: IReactQueryProviderProps): JSX.Element {
  const queryClientRef = useRef<QueryClient>();
  const router = useRouter();
  const showNotFound = useShowNotFound();
  const showError = useShowError();

  async function handleError(err: unknown) {
    if (err instanceof HttpError) {
      if (err.statusCode === NOT_FOUND) {
        showNotFound(true);
      }
      if (err.statusClass === INTERNAL_SERVER_ERROR) {
        showError(true);
      }
      if (err.statusCode === UNAUTHORIZED) {
        await queryClientRef.current!.cancelQueries();
        queryClientRef.current!.clear();

        await router.push({
          pathname: "/resignin",
          query: { href: router.asPath },
        });
      }
    }
  }

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          onError: handleError,
          // replace gateway URL here (?)
          queryFn: buildQueryFn(),
        },
      },
    });
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={dehydratedState}>{children}</Hydrate>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
}
