import { Components, getChipUtilityClass } from "@mui/material";

import { baseTheme } from "src/theme/base";

const colorInfo = getChipUtilityClass("colorInfo");
const colorError = getChipUtilityClass("colorError");
const colorWarning = getChipUtilityClass("colorWarning");
const colorSuccess = getChipUtilityClass("colorSuccess");
const pill = getChipUtilityClass("pill");

export const MuiChip: NonNullable<Components["MuiChip"]> = {
  defaultProps: {
    variant: "outlined",
    size: "small",
  },
  styleOverrides: {
    root: {
      borderRadius: "2px",

      [`&.${colorInfo}`]: {
        backgroundColor: baseTheme.palette.info.light,
      },
      [`&.${colorError}`]: {
        backgroundColor: baseTheme.palette.error.light,
      },
      [`&.${colorWarning}`]: {
        backgroundColor: baseTheme.palette.warning.light,
      },
      [`&.${colorSuccess}`]: {
        backgroundColor: baseTheme.palette.success.light,
      },

      [`&.${pill}`]: {
        borderRadius: "999rem",
        height: "auto",
        padding: baseTheme.spacing(0.25, 0.5),
      },
    },
    sizeSmall: {
      height: "1.1875rem",
    },
    labelSmall: {
      fontSize: "0.625rem",
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    sizeMedium: {
      padding: baseTheme.spacing(0.5, 1),
      fontSize: baseTheme.typography.body2.fontSize,
      lineHeight: baseTheme.typography.body2.lineHeight,
    },
    labelMedium: {
      padding: baseTheme.spacing(0, 0, 0, 0.5),
    },
  },
};
