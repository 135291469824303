import { Components } from "@mui/material";

import { baseTheme } from "../base";

export const MuiSlider: NonNullable<Components["MuiSlider"]> = {
  styleOverrides: {
    track: {
      height: "4px",
      borderRadius: "999rem",
    },
    rail: {
      color: baseTheme.palette.divider,
      height: "4px",
      borderRadius: "999rem",
    },
    thumb: {
      height: "0.875rem",
      width: "0.875rem",
      color: "white",
      boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
    },
  },
};
