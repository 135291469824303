import { Components, getSwitchUtilityClass } from "@mui/material";

import { baseTheme as theme } from "src/theme/base";
import { remToPx } from "src/utilities/remToPx";

const checkedClass = getSwitchUtilityClass("checked");

const baseWidth = remToPx(2.19);
const baseHeight = remToPx(1.22);
const thumbSpacing = 2;
const thumbSize = baseHeight - thumbSpacing * 2;

export const MuiSwitch: NonNullable<Components["MuiSwitch"]> = {
  styleOverrides: {
    root: {
      width: baseWidth,
      height: baseHeight,
      padding: 0,
      margin: theme.spacing(1),
      overflow: "unset",
    },
    switchBase: {
      padding: thumbSpacing,
      [`&.${checkedClass}`]: {
        transform: `translateX(${baseWidth / 2 - thumbSpacing}px)`,
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.black.main,
        },
      },
    },
    track: {
      opacity: 1,
      backgroundColor: theme.palette.tillGrey.light,
      transition: theme.transitions.create(["background-color"]),
      borderRadius: "999rem",
    },
    thumb: {
      width: thumbSize,
      height: thumbSize,
      backgroundColor: theme.palette.white.main,
    },
  },
};
