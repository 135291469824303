import { createTheme, alpha } from "@mui/material";

import { baseTheme } from "./base";
import * as components from "./components";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

export const boxShadow = `0 0 0.5rem 0 ${alpha(
  baseTheme.palette.common.black,
  0.15
)}`;

const theme = createTheme(baseTheme, {
  components,
});

export const darkDividerColor = "#DDDDDD";

export default theme;
