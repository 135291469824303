import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiLink: NonNullable<Components["MuiLink"]> = {
  styleOverrides: {
    root: {
      color: baseTheme.palette.tillBlue,
      "&:focus-visible": {
        outline: "none",
        textDecoration: "underline",
      },
    },
  },
};
