import { ReactNode } from "react";
import Head from "next/head";
import { Box } from "@mui/system";

import { PageLoadingSpinner } from "src/components/PageLoadingSpinner";
import usePortalConfig from "src/hooks/usePortalConfig";

function DelayedLoadingSpinner({
  isLoading,
}: {
  isLoading: boolean;
}): JSX.Element {
  if (!isLoading) {
    return <></>;
  }

  return (
    <Box color="grey.400">
      <PageLoadingSpinner color="inherit" />
    </Box>
  );
}

export interface ILayoutProps {
  title: string;
  children: ReactNode;
}

export function Layout({ title, children }: ILayoutProps): JSX.Element {
  const {
    isLoading: isThemeLoading,
    isFetchedAfterMount,
    data: portalConfig,
  } = usePortalConfig();

  const pageTitle = portalConfig?.entityName
    ? `${title} - ${portalConfig.entityName}`
    : `${title}`;
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
      </Head>
      {!isFetchedAfterMount ? (
        <DelayedLoadingSpinner isLoading={isThemeLoading} />
      ) : (
        children
      )}
    </>
  );
}
