import { alpha, ThemeOptions } from "@mui/material";

export const palette: NonNullable<ThemeOptions["palette"]> = {
  background: {
    default: "#ffffff",
    secondary: "#fafafa",
    shaded: "#f2f2f2",
  },
  common: {
    black: "#222",
    white: "#fff",
  },
  primary: {
    main: "#e40046",
    light: "#ff3f41",
  },
  error: {
    light: "#fbeaea",
    main: "#ff3f41",
    contrastText: "#66191a",
  },
  success: {
    light: "#eaf2ea",
    main: "#15cc2d",
    contrastText: "#133213",
  },
  info: {
    light: "#e6f3fa",
    main: "#2196f3",
    contrastText: "#0c3c61",
  },
  warning: {
    light: "#fdf0e6",
    main: "#5f2b01",
    contrastText: "#644507",
  },
  default: {
    light: "#f2f2f2", // unknown
    main: "#f2f2f2",
    dark: "#f2f2f2", // unknown
    contrastText: "#222222",
  },
  secondary: {
    main: "#c193b7",
  },
  white: {
    main: "#fff",
    dark: "#f5f5f5",
    contrastText: "#222222",
    light: "#fff",
  },
  black: {
    main: "#222",
    dark: "#000",
    contrastText: "#fff",
    light: "#555555",
  },
  clear: {
    main: alpha("#000", 0),
    dark: alpha("#000", 0.04),
    contrastText: "#222222",
    light: alpha("#000", 0),
  },
  text: {
    primary: "#222222",
    secondary: "#6C737F",
    disabled: "#222222",
    hint: "#555555",
  },
  action: {
    disabled: "#EEEEEE",
  },
  tillGrey: {
    dark: "#555555",
    main: "#767676",
    light: "#BBBBBB",
    contrastText: "#FFFFFF",
  },
  muted: {
    dark: "#C1C1C1",
    main: "#dcdcdc",
    light: "#f8f8f8",
    contrastText: "#6e6e6e",
  },
  revised: {
    dark: "#fdf0e6",
    light: "#fdf0e6",
    main: "#5f2b01",
    contrastText: "#644507",
  },
  data: {
    black: "#000000",
    gray: "#DDDDDD",
    green: "#5eb79e",
  },
  divider: "#D2D6DB",
  tillBlue: "#0071CD",
  tillOrange: {
    light: "#FFE6D5",
    main: "#e40046",
    dark: "#771A0D",
    contrastText: "#fff",
  },
  stepper: {
    completedBg: "#e6f4d7",
    completedText: "#3f621a",
  },
  emailVerification: {
    success: {
      backgroundColor: "#E6F4D7",
    },
    warning: {
      backgroundColor: "#FEF7C3",
      cta: {
        color: "#00359E",
      },
    },
  },
};
