import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiSvgIcon: NonNullable<Components["MuiSvgIcon"]> = {
  styleOverrides: {
    colorSecondary: {
      color: baseTheme.palette.divider,
    },
  },
};
