import { Components } from "@mui/material";

export const MuiFormHelperText: NonNullable<Components["MuiFormHelperText"]> = {
  styleOverrides: {
    root: {
      fontSize: "0.875rem",
      margin: 0,
    },
  },
};
