import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiTab: NonNullable<Components["MuiTab"]> = {
  styleOverrides: {
    root: {
      minHeight: 0,
      height: `calc(${baseTheme.spacing(3)} + 1rem)`,
      textTransform: "capitalize",
      minWidth: "0 !important",
      padding: "0",
      paddingTop: baseTheme.spacing(1),
      paddingBottom: baseTheme.spacing(2),
      marginRight: baseTheme.spacing(2),
      "&:last-of-type": {
        marginRight: 0,
      },
      borderRadius: "4px 4px 0",
      "&.Mui-selected": {
        fontWeight: baseTheme.typography.fontWeightMedium,
        color: baseTheme.palette.common.black,
      },
    },
  },
};
