import { styled } from "@mui/material/styles";

const PREFIX = "ActiveSession";

const classes = {
  line: `${PREFIX}-line`,
  exclamation: `${PREFIX}-exclamation`,
  globe: `${PREFIX}-globe`,
};

const Root = styled("svg")(({ theme }) => {
  return {
    [`& .${classes.line}`]: {
      fill: "none",
      stroke: theme.palette.common.black,
      strokeWidth: 4,
    },
    [`& .${classes.exclamation}`]: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      fill: theme.palette.error.main,
    },
    [`& .${classes.globe}`]: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      fill: theme.palette.common.black,
    },
  };
});

export function InvalidLink(): JSX.Element {
  return (
    <Root
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="88px"
      height="88px"
      viewBox="0 0 88 88"
      version="1.1"
    >
      <path
        className={classes.globe}
        d="M44,21c-12.7,0-23,10.3-23,23s10.3,23,23,23s23-10.3,23-23S56.7,21,44,21z M41.6,63C32.2,61.8,25,53.5,25,43.4
        c0-1.5,0.2-3,0.5-4.4l11.4,11.9v2.5c0,2.7,2.1,5,4.8,5V63z M53.5,53.3c2.1,0,3.9,1.4,4.5,3.4c3.1-3.5,5-8.2,5-13.4
        c0-8.3-4.9-15.4-11.9-18.4v1c0,2.7-2.1,5-4.8,5h-4.7v5c0,1.4-1.1,2.5-2.4,2.5h-4.8v5h14.2c1.3,0,2.4,1.1,2.4,2.5v7.4H53.5z"
      />
      <path
        className={classes.line}
        d="M83.9,57.1C85.3,53,86,48.6,86,44C86,20.8,67.2,2,44,2S2,20.8,2,44s18.8,42,42,42c4.5,0,8.9-0.7,13-2"
      />
      <path
        className={classes.exclamation}
        d="M74,60c7.7,0,14,6.3,14,14c0,7.7-6.3,14-14,14c-7.7,0-14-6.3-14-14C60,66.3,66.3,60,74,60z M74,78c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S75.1,78,74,78z M74,66c-1.1,0-2,0.9-2,2l0,0v6c0,1.1,0.9,2,2,2s2-0.9,2-2l0,0v-6 C76,66.9,75.1,66,74,66z"
      />
    </Root>
  );
}
