import { alpha, Components, getMenuItemUtilityClass } from "@mui/material";

import { baseTheme } from "src/theme/base";

const selected = getMenuItemUtilityClass("selected");

export const MuiMenuItem: NonNullable<Components["MuiMenuItem"]> = {
  styleOverrides: {
    root: {
      borderBottom: baseTheme.shape.solidBorder,
      "&:last-of-type": {
        borderBottom: "none",
      },
      [`&.${selected}`]: {
        backgroundColor: alpha(baseTheme.palette.tillGrey.light, 0.75),
        ":hover": {
          backgroundColor: alpha(baseTheme.palette.tillGrey.light, 0.75),
        },
      },
      [`:hover`]: {
        backgroundColor: baseTheme.palette.divider,
      },
    },
  },
};
