import { Components } from "@mui/material";

import { EIcon, Icon } from "src/components/Icon";

export const MuiCheckbox: NonNullable<Components["MuiCheckbox"]> = {
  defaultProps: {
    icon: <Icon icon={EIcon.CHECKBOX} />,
    checkedIcon: <Icon icon={EIcon.CHECKBOX_FILLED} />,
    indeterminateIcon: <Icon icon={EIcon.CHECKBOX_FILLED_INDECISIVE} />,
  },
};
