import { Components } from "@mui/material";

import { baseTheme } from "src/theme/base";

export const MuiCard: NonNullable<Components["MuiCard"]> = {
  defaultProps: {
    elevation: 0,
    variant: "outlined",
  },
  styleOverrides: {
    root: {
      padding: baseTheme.spacing(1),
    },
  },
};
