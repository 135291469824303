import { alpha, ThemeOptions } from "@mui/material";

import { palette } from "./palette";

export const shape: NonNullable<ThemeOptions["shape"]> = ((divider, black) => {
  if (!divider || !black) {
    throw new Error(
      "palette.divider and palette.common.black must be configured"
    );
  }
  return {
    borderRadius: 6,
    solidBorder: `1px solid ${divider}`,
    boxShadow: `0 0 0.5rem 0 ${alpha(black, 0.15)}`,
  };
})(palette.divider, palette.common?.black);
