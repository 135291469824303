import Head from "next/head";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useState } from "react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { CacheProvider, EmotionCache } from "@emotion/react";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import "react-datepicker/dist/react-datepicker.css";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { SingletonHooksContainer } from "react-singleton-hook";
import { FlagsProvider } from "flagged";

import createEmotionCache from "src/theme/createEmotionCache";
import defaultTheme from "src/theme";
import { AuthBroadcastChannel } from "src/components/AuthBroadcastChannel";
import { ActiveSessionDialog } from "src/pageComponents/_App/ActiveSessionDialog";
import { ReactQueryProvider } from "src/contexts/ReactQuery";
import { ErrorProvider } from "src/contexts/Error";
import { Intl } from "src/contexts/Intl";
import {
  amplitudePageViewTracking,
  initAmplitude,
} from "src/utilities/amplitude";
import { CustomThemeProvider } from "src/pageComponents/_App/CustomThemeProvider";

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (
    page: ReactElement,
    pageTitle: string,
    pageTitleProps: Record<
      string,
      string | number | boolean | Date | null | undefined
    >
  ) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  emotionCache?: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppPropsWithLayout): JSX.Element {
  if (!LicenseInfo.getLicenseKey()) {
    LicenseInfo.setLicenseKey(
      "61c2e3922cc98855ec43fdfb9749a3a0T1JERVI6NDAwODcsRVhQSVJZPTE2Nzk0NDc3MDIwMDAsS0VZVkVSU0lPTj0x"
    );
  }

  // eslint-disable-next-line
  const [favicon, setFavicon] = useState<string | undefined>();

  useEffect(() => {
    initAmplitude();
  }, []);

  const { locale, pathname } = useRouter();

  useEffect(() => {
    amplitudePageViewTracking(pathname);
  }, [pathname]);

  const getLayout =
    Component.getLayout ??
    ((page) => {
      return page;
    });

  return (
    <FlagsProvider features={{ terminalActivation: false }}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>Till - NextGen</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
          <link rel="icon" href={favicon} />
        </Head>
        <Intl messages={pageProps.messages} locale={locale}>
          <ReactQueryProvider dehydratedState={pageProps.dehydratedState}>
            <CustomThemeProvider
              defaultTheme={defaultTheme}
              onConfigChange={(newConfig) => {
                setFavicon(newConfig?.favicon);
              }}
            >
              <CssBaseline />
              <ErrorProvider
                showError={pageProps.error}
                showNotFound={pageProps.notFound}
              >
                <AuthBroadcastChannel />
                <SingletonHooksContainer />
                {/* Temporarily set the pageTitle and pageTitleProps for the AcceptInvitePage explicitly (MIN-254)
                 * once the page is converted to static page, could be removed,
                 */}
                {getLayout(
                  <div style={{ width: "100%", height: "100%" }}>
                    <Component {...pageProps} />
                  </div>,
                  pageProps.title,
                  pageProps.titleProps
                )}
              </ErrorProvider>
              <ActiveSessionDialog />
            </CustomThemeProvider>
          </ReactQueryProvider>
        </Intl>
      </CacheProvider>
    </FlagsProvider>
  );
}

export default MyApp;
