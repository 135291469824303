import { alpha, autocompleteClasses, Components } from "@mui/material";

import { EIcon, EIconSize, Icon } from "src/components/Icon";
import { baseTheme } from "src/theme/base";

export const MuiAutocomplete: NonNullable<Components["MuiAutocomplete"]> = {
  defaultProps: {
    popupIcon: <Icon icon={EIcon.CHEVRON_DOWN} size={EIconSize.XS} />,
    clearIcon: <Icon icon={EIcon.CLOSE} size={EIconSize.XS} />,
  },
  styleOverrides: {
    listbox: {
      overflowY: "scroll",
      [`& .${autocompleteClasses.option}`]: {
        justifyContent: "space-between",
      },
      [`& .${autocompleteClasses.option}[aria-selected="true"]`]: {
        backgroundColor: "transparent",
      },
      [`& .${autocompleteClasses.option}[aria-selected="true"].${autocompleteClasses.focused}`]:
        {
          backgroundColor: alpha(baseTheme.palette.data.black, 0.15),
        },
    },
  },
};
