export function remToPx(rem: number): number {
  if (typeof window === "undefined") {
    // 16px to act as a base font size for SSR
    return rem * 16;
  }
  return (
    rem *
    parseFloat(
      window.getComputedStyle(document.documentElement).fontSize || "16"
    )
  );
}
