import { ThemeOptions } from "@mui/material";

export const typography: NonNullable<ThemeOptions["typography"]> = (
  palette
) => ({
  allVariants: {
    letterSpacing: 0,
    fontWeight: 400,
  },
  h1: {
    fontSize: "1.375rem",
    lineHeight: 1.167,
  },
  h2: {
    fontSize: "1.25rem",
    lineHeight: 1.2,
  },
  h3: {
    fontSize: "1.125rem",
    lineHeight: 1.167,
  },
  body1: {
    fontSize: "1rem",
    lineHeight: 1.5,
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: 1.43,
  },
  subtitle1: {
    fontSize: "0.875rem",
    color: palette.text.secondary,
    letterSpacing: "0em",
    lineHeight: "1.25rem",
  },
  subtitle2: {
    fontSize: "0.75rem",
    color: palette.text.secondary,
    letterSpacing: "0em",
    lineHeight: "1.25rem",
  },
});
