import { Theme, createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";

import { IPortalConfigResponse } from "types/portalConfig/IPortalConfigResponse";
import usePortalConfig from "src/hooks/usePortalConfig";

interface ICustomThemeProviderProps {
  children: React.ReactNode;
  defaultTheme: Theme;
  onConfigChange?: (newConfig?: IPortalConfigResponse) => void;
}

export function CustomThemeProvider({
  children,
  defaultTheme,
  onConfigChange,
}: ICustomThemeProviderProps): JSX.Element {
  const { data: portalConfig, isFetched } = usePortalConfig();

  const previousConfig = useRef(portalConfig);

  useEffect(() => {
    if (
      isFetched &&
      onConfigChange &&
      previousConfig.current !== portalConfig
    ) {
      onConfigChange(portalConfig);
    } else if (isFetched && onConfigChange) {
      onConfigChange({
        favicon: portalConfig?.logoUrls?.favicon ?? "/tillicon.ico",
      } as IPortalConfigResponse);
    }
  }, [isFetched, portalConfig, onConfigChange]);

  const theme = useMemo(
    () =>
      isFetched && portalConfig?.themeColors
        ? createTheme(defaultTheme, { palette: portalConfig.themeColors })
        : defaultTheme,
    [portalConfig?.themeColors, isFetched, defaultTheme]
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
