import { ButtonProps, Components, getButtonUtilityClass } from "@mui/material";
import { Theme } from "@mui/system";

export type IButtonProps = ButtonProps & {
  autoWidth?: boolean;
  component?: string;
};

const contained = getButtonUtilityClass("contained");
const containedWhite = getButtonUtilityClass("containedWhite");
const containedBlack = getButtonUtilityClass("containedBlack");
const disabled = getButtonUtilityClass("disabled");
const text = getButtonUtilityClass("text");
const startIcon = getButtonUtilityClass("startIcon");
const endIcon = getButtonUtilityClass("endIcon");

export const MuiButton: NonNullable<Components<Theme>["MuiButton"]> = {
  defaultProps: {
    variant: "contained",
    disableElevation: true,
    color: "black",
  },
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: "none",
      fontSize: "1rem",
      letterSpacing: "0rem",
      whiteSpace: "nowrap",

      [`&.${disabled}`]: {
        backgroundColor: `${theme.palette.tillGrey.light} !important`,
      },

      [`&.${contained}`]: {
        lineHeight: 1,
        borderColor: "transparent",
        padding: theme.spacing(1, 3),
        borderRadius: "999rem",
        maxWidth: "15.625rem",
        width: "100%",
        minWidth: "auto",
        minHeight: "2.75rem",
        ":hover": {
          backgroundColor: theme.palette.primary,
        },
      },

      [`&.${containedWhite}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        border: theme.shape.solidBorder,
        ":hover": {
          backgroundColor: theme.palette.white.dark,
        },
      },

      [`&.${containedBlack}`]: {
        ":hover": {
          backgroundColor: theme.palette.black.light,
        },
      },

      [`&.${text}`]: {
        borderRadius: "999rem",
        lineHeight: 1,
        backgroundColor: "transparent !important",
        minHeight: 0,
        padding: "0 5px",
        "& .MuiTouchRipple-ripple": {
          color: theme.palette.tillGrey.light,
        },
      },
      [`& .${startIcon}`]: {
        marginLeft: 0,
        marginRight: theme.spacing(1),
      },
      [`& .${endIcon}`]: {
        marginLeft: theme.spacing(1),
        marginRight: 0,
      },
    }),
  },
};
