import amplitude from "amplitude-js";

export const initAmplitude = (): void => {
  if (process.env.AMPLITUDE_API_KEY) {
    amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY as string);
  }
};

export const setAmplitudeUserId = (userId: string): void => {
  if (amplitude.getInstance().getSessionId()) {
    amplitude.getInstance().setUserId(userId);
  }
};

// TODO: properties object will have to decided upon with product at a later stage.
export const setAmplitudeUserProperties = (
  properties: Record<string, unknown>
): void => {
  if (amplitude.getInstance().getSessionId()) {
    amplitude.getInstance().setUserProperties(properties);
  }
};

export const sendAmplitudeData = (
  eventType: string,
  eventProperties = {}
): void => {
  if (amplitude.getInstance().getSessionId()) {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }
};

const amplitudeUrls = [
  "/signin",
  "/register",
  "/dashboard",
  "/transactions",
  "/payouts",
  "/statements",
  "/locations",
  "[merchantType]/[merchantId]/settings",
  "[merchantType]/[merchantId]/settings/users",
  "/profile/account-details",
  "[merchantType]/[merchantId]/settings/users/invite",
  "[merchantType]/[merchantId]/settings/users/[userId]",
  "/help-and-support",
];

export const amplitudePageViewTracking = (pathname: string): void => {
  if (amplitudeUrls.includes(pathname)) {
    sendAmplitudeData(`View${pathname}`);
  }
};
